import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/css/index.css';

//import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import reportWebVitals from './reportWebVitals';
import { setupAxiosInstance } from './interceptors/axios';
import { FilterMatchMode, PrimeReactProvider } from 'primereact/api';

import { Provider } from 'react-redux';
import { configureStore } from './store/configureStore';
//import './interceptors/axios'
export { Provider } from "react-redux";




const root = ReactDOM.createRoot(document.getElementById('root'));
const store = configureStore();
setupAxiosInstance(store)


const value = {
  appendTo: "self",
  filterMatchMode: {
    text: [FilterMatchMode.STARTS_WITH, FilterMatchMode.CONTAINS, FilterMatchMode.NOT_CONTAINS, FilterMatchMode.ENDS_WITH, FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS],
    numeric: [FilterMatchMode.EQUALS, FilterMatchMode.NOT_EQUALS, FilterMatchMode.LESS_THAN, FilterMatchMode.LESS_THAN_OR_EQUAL_TO, FilterMatchMode.GREATER_THAN, FilterMatchMode.GREATER_THAN_OR_EQUAL_TO],
    date: [FilterMatchMode.DATE_IS, FilterMatchMode.DATE_IS_NOT, FilterMatchMode.DATE_BEFORE, FilterMatchMode.DATE_AFTER],
    hideOverlaysOnDocumentScrolling: true,
    zIndex: {
      modal: 1100,    // dialog, sidebar
      overlay: 1000,  // dropdown, overlaypanel
      menu: 1000,     // overlay menus
      tooltip: 1100,   // tooltip
      toast: 1200     // toast
    },
    autoZIndex: true,
    unstyled: true
  },
}

root.render(

  <Provider store={store}>  
      <PrimeReactProvider value={value}>
        <App />
      </PrimeReactProvider>   
  </Provider>


);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
